<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TempLeftNav />
    <div class="page">
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Maintenance</h1>
              <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div>
            </div>
            <p>
              A maintenance page informs users that the site they’re trying to
              reach is temporarily unavailable because the site or app has been
              taken offline for routine maintenance or upgrades.
            </p>
            <div class="gray-250 p-30 w800 mtb-30">
              <div class="font-red semibold">Suggested messaging:</div>
              <p>
                At minimum, inform the user that the site is currently down and
                to return later. For example:
              </p>
              <ul class="bullet-red">
                <li>[site name] is offline for maintenance</li>
                <li>
                  We apologize for the inconvenience, please try again later
                </li>
              </ul>
              <p>
                If possible and practical, include the reason for the down time
                and an estimated time for when the site will return. For
                example:
              </p>
              <ul class="bullet-red">
                <li>
                  [site name] is offline for maintenance while we upgrade our
                  servers
                </li>
                <li>
                  We apologize for the inconvenience, please try again after 10
                  p.m. tonight
                </li>
              </ul>
            </div>
            <p style="font-style:italic">
              Note – copy the formatting from the error pages.
            </p>
          </div>
        </section>

        <div class="divider w1200"></div>

        <section>
          <div class="content-band">
            <h3>Design Vew</h3>
            <CodeView block="pages" type="maintenance-page">
              <MaintenancePageComponent type="primary" text="MaintenancePage" />
            </CodeView>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import TempLeftNav from "../TempLeftNav";
import MaintenancePageComponent from "../MaintenancePageComponent";
import CodeView from "../CodeView";
export default {
  name: "ServerError",
  data: function() {
    return {};
  },
  components: {
    TempLeftNav,
    MaintenancePageComponent,
    CodeView,
  },
};
</script>
