<template>
  <article>
    <div class="page-container">
      <main style="display: contents">
        <section class="height-full mtb-auto">
          <div class="content-band flex align-center justify-center max-w1000">
            <div
              class="
                container
                basis-50
                max-w500
                flex
                align-center
                justify-center
              "
            >
              <img
                src="../assets/MaintenancePage.png"
                class="error-image"
                style="width: 238px; height: 226px"
                alt="Error Maintenance Image"
              />
            </div>
            <div
              class="
                container
                flex
                justify-center
                align-center
                basis-50
                error-message
              "
            >
              <div class="container max-w500">
                <h1
                  class="
                    font-iop-blue
                    error-message-large
                    large
                    semibold
                    mtb-10
                  "
                >
                  InDemand.Ohio.gov is offline for maintenance
                </h1>
                <div class="error-text mtb-10">
                  We apologize for the inconvenience, please try again later
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <div
        class="container flex justify-center align-center gray-200"
        style="height: 120px"
      >
        <div>
          <img
            src="../assets/iop-logo-gray.png"
            alt="Innovate Ohio Platform Logo"
          />
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: "MaintenancePageComponent",
  props: ["type", "subtype", "text", "icon"],
};
</script>

<style scoped>
.error-text {
  color: #557aed;
  font-size: 22px;
}
.error-message-large {
    font-size: 34px;
    line-height: 1;
}
@media only screen and (max-width: 600px) {
  .error-message {
    text-align: -webkit-center;
  }
  .error-message-large {
    font-size: 30px !important;
    line-height: 1 !important;
  }
  .error-text {
    font-size: 24px !important;
    line-height: 1 !important;
  }
  .error-image {
    padding: 10px !important;
  }
}
</style>
